import { Role as G, SessionAccessRuleStatus as Q } from "@waitroom/models";
var _t = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, ct = { exports: {} };
(function(e, t) {
  (function(u, i) {
    e.exports = i();
  })(_t, function() {
    var u = 1e3, i = 6e4, a = 36e5, S = "millisecond", d = "second", g = "minute", $ = "hour", N = "day", w = "week", R = "month", F = "quarter", D = "year", Y = "date", st = "Invalid Date", ot = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, at = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, ut = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(E) {
      var r = ["th", "st", "nd", "rd"], s = E % 100;
      return "[" + E + (r[(s - 20) % 10] || r[s] || r[0]) + "]";
    } }, J = function(E, r, s) {
      var o = String(E);
      return !o || o.length >= r ? E : "" + Array(r + 1 - o.length).join(s) + E;
    }, Et = { s: J, z: function(E) {
      var r = -E.utcOffset(), s = Math.abs(r), o = Math.floor(s / 60), n = s % 60;
      return (r <= 0 ? "+" : "-") + J(o, 2, "0") + ":" + J(n, 2, "0");
    }, m: function E(r, s) {
      if (r.date() < s.date()) return -E(s, r);
      var o = 12 * (s.year() - r.year()) + (s.month() - r.month()), n = r.clone().add(o, R), _ = s - n < 0, c = r.clone().add(o + (_ ? -1 : 1), R);
      return +(-(o + (s - n) / (_ ? n - c : c - n)) || 0);
    }, a: function(E) {
      return E < 0 ? Math.ceil(E) || 0 : Math.floor(E);
    }, p: function(E) {
      return { M: R, y: D, w, d: N, D: Y, h: $, m: g, s: d, ms: S, Q: F }[E] || String(E || "").toLowerCase().replace(/s$/, "");
    }, u: function(E) {
      return E === void 0;
    } }, U = "en", M = {};
    M[U] = ut;
    var nt = "$isDayjsObject", Z = function(E) {
      return E instanceof W || !(!E || !E[nt]);
    }, q = function E(r, s, o) {
      var n;
      if (!r) return U;
      if (typeof r == "string") {
        var _ = r.toLowerCase();
        M[_] && (n = _), s && (M[_] = s, n = _);
        var c = r.split("-");
        if (!n && c.length > 1) return E(c[0]);
      } else {
        var l = r.name;
        M[l] = r, n = l;
      }
      return !o && n && (U = n), n || !o && U;
    }, O = function(E, r) {
      if (Z(E)) return E.clone();
      var s = typeof r == "object" ? r : {};
      return s.date = E, s.args = arguments, new W(s);
    }, f = Et;
    f.l = q, f.i = Z, f.w = function(E, r) {
      return O(E, { locale: r.$L, utc: r.$u, x: r.$x, $offset: r.$offset });
    };
    var W = function() {
      function E(s) {
        this.$L = q(s.locale, null, !0), this.parse(s), this.$x = this.$x || s.x || {}, this[nt] = !0;
      }
      var r = E.prototype;
      return r.parse = function(s) {
        this.$d = function(o) {
          var n = o.date, _ = o.utc;
          if (n === null) return /* @__PURE__ */ new Date(NaN);
          if (f.u(n)) return /* @__PURE__ */ new Date();
          if (n instanceof Date) return new Date(n);
          if (typeof n == "string" && !/Z$/i.test(n)) {
            var c = n.match(ot);
            if (c) {
              var l = c[2] - 1 || 0, A = (c[7] || "0").substring(0, 3);
              return _ ? new Date(Date.UTC(c[1], l, c[3] || 1, c[4] || 0, c[5] || 0, c[6] || 0, A)) : new Date(c[1], l, c[3] || 1, c[4] || 0, c[5] || 0, c[6] || 0, A);
            }
          }
          return new Date(n);
        }(s), this.init();
      }, r.init = function() {
        var s = this.$d;
        this.$y = s.getFullYear(), this.$M = s.getMonth(), this.$D = s.getDate(), this.$W = s.getDay(), this.$H = s.getHours(), this.$m = s.getMinutes(), this.$s = s.getSeconds(), this.$ms = s.getMilliseconds();
      }, r.$utils = function() {
        return f;
      }, r.isValid = function() {
        return this.$d.toString() !== st;
      }, r.isSame = function(s, o) {
        var n = O(s);
        return this.startOf(o) <= n && n <= this.endOf(o);
      }, r.isAfter = function(s, o) {
        return O(s) < this.startOf(o);
      }, r.isBefore = function(s, o) {
        return this.endOf(o) < O(s);
      }, r.$g = function(s, o, n) {
        return f.u(s) ? this[o] : this.set(n, s);
      }, r.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, r.valueOf = function() {
        return this.$d.getTime();
      }, r.startOf = function(s, o) {
        var n = this, _ = !!f.u(o) || o, c = f.p(s), l = function(P, T) {
          var v = f.w(n.$u ? Date.UTC(n.$y, T, P) : new Date(n.$y, T, P), n);
          return _ ? v : v.endOf(N);
        }, A = function(P, T) {
          return f.w(n.toDate()[P].apply(n.toDate("s"), (_ ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(T)), n);
        }, p = this.$W, m = this.$M, h = this.$D, b = "set" + (this.$u ? "UTC" : "");
        switch (c) {
          case D:
            return _ ? l(1, 0) : l(31, 11);
          case R:
            return _ ? l(1, m) : l(0, m + 1);
          case w:
            var K = this.$locale().weekStart || 0, L = (p < K ? p + 7 : p) - K;
            return l(_ ? h - L : h + (6 - L), m);
          case N:
          case Y:
            return A(b + "Hours", 0);
          case $:
            return A(b + "Minutes", 1);
          case g:
            return A(b + "Seconds", 2);
          case d:
            return A(b + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, r.endOf = function(s) {
        return this.startOf(s, !1);
      }, r.$set = function(s, o) {
        var n, _ = f.p(s), c = "set" + (this.$u ? "UTC" : ""), l = (n = {}, n[N] = c + "Date", n[Y] = c + "Date", n[R] = c + "Month", n[D] = c + "FullYear", n[$] = c + "Hours", n[g] = c + "Minutes", n[d] = c + "Seconds", n[S] = c + "Milliseconds", n)[_], A = _ === N ? this.$D + (o - this.$W) : o;
        if (_ === R || _ === D) {
          var p = this.clone().set(Y, 1);
          p.$d[l](A), p.init(), this.$d = p.set(Y, Math.min(this.$D, p.daysInMonth())).$d;
        } else l && this.$d[l](A);
        return this.init(), this;
      }, r.set = function(s, o) {
        return this.clone().$set(s, o);
      }, r.get = function(s) {
        return this[f.p(s)]();
      }, r.add = function(s, o) {
        var n, _ = this;
        s = Number(s);
        var c = f.p(o), l = function(m) {
          var h = O(_);
          return f.w(h.date(h.date() + Math.round(m * s)), _);
        };
        if (c === R) return this.set(R, this.$M + s);
        if (c === D) return this.set(D, this.$y + s);
        if (c === N) return l(1);
        if (c === w) return l(7);
        var A = (n = {}, n[g] = i, n[$] = a, n[d] = u, n)[c] || 1, p = this.$d.getTime() + s * A;
        return f.w(p, this);
      }, r.subtract = function(s, o) {
        return this.add(-1 * s, o);
      }, r.format = function(s) {
        var o = this, n = this.$locale();
        if (!this.isValid()) return n.invalidDate || st;
        var _ = s || "YYYY-MM-DDTHH:mm:ssZ", c = f.z(this), l = this.$H, A = this.$m, p = this.$M, m = n.weekdays, h = n.months, b = n.meridiem, K = function(T, v, k, x) {
          return T && (T[v] || T(o, _)) || k[v].slice(0, x);
        }, L = function(T) {
          return f.s(l % 12 || 12, T, "0");
        }, P = b || function(T, v, k) {
          var x = T < 12 ? "AM" : "PM";
          return k ? x.toLowerCase() : x;
        };
        return _.replace(at, function(T, v) {
          return v || function(k) {
            switch (k) {
              case "YY":
                return String(o.$y).slice(-2);
              case "YYYY":
                return f.s(o.$y, 4, "0");
              case "M":
                return p + 1;
              case "MM":
                return f.s(p + 1, 2, "0");
              case "MMM":
                return K(n.monthsShort, p, h, 3);
              case "MMMM":
                return K(h, p);
              case "D":
                return o.$D;
              case "DD":
                return f.s(o.$D, 2, "0");
              case "d":
                return String(o.$W);
              case "dd":
                return K(n.weekdaysMin, o.$W, m, 2);
              case "ddd":
                return K(n.weekdaysShort, o.$W, m, 3);
              case "dddd":
                return m[o.$W];
              case "H":
                return String(l);
              case "HH":
                return f.s(l, 2, "0");
              case "h":
                return L(1);
              case "hh":
                return L(2);
              case "a":
                return P(l, A, !0);
              case "A":
                return P(l, A, !1);
              case "m":
                return String(A);
              case "mm":
                return f.s(A, 2, "0");
              case "s":
                return String(o.$s);
              case "ss":
                return f.s(o.$s, 2, "0");
              case "SSS":
                return f.s(o.$ms, 3, "0");
              case "Z":
                return c;
            }
            return null;
          }(T) || c.replace(":", "");
        });
      }, r.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, r.diff = function(s, o, n) {
        var _, c = this, l = f.p(o), A = O(s), p = (A.utcOffset() - this.utcOffset()) * i, m = this - A, h = function() {
          return f.m(c, A);
        };
        switch (l) {
          case D:
            _ = h() / 12;
            break;
          case R:
            _ = h();
            break;
          case F:
            _ = h() / 3;
            break;
          case w:
            _ = (m - p) / 6048e5;
            break;
          case N:
            _ = (m - p) / 864e5;
            break;
          case $:
            _ = m / a;
            break;
          case g:
            _ = m / i;
            break;
          case d:
            _ = m / u;
            break;
          default:
            _ = m;
        }
        return n ? _ : f.a(_);
      }, r.daysInMonth = function() {
        return this.endOf(R).$D;
      }, r.$locale = function() {
        return M[this.$L];
      }, r.locale = function(s, o) {
        if (!s) return this.$L;
        var n = this.clone(), _ = q(s, o, !0);
        return _ && (n.$L = _), n;
      }, r.clone = function() {
        return f.w(this.$d, this);
      }, r.toDate = function() {
        return new Date(this.valueOf());
      }, r.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, r.toISOString = function() {
        return this.$d.toISOString();
      }, r.toString = function() {
        return this.$d.toUTCString();
      }, E;
    }(), rt = W.prototype;
    return O.prototype = rt, [["$ms", S], ["$s", d], ["$m", g], ["$H", $], ["$W", N], ["$M", R], ["$y", D], ["$D", Y]].forEach(function(E) {
      rt[E[1]] = function(r) {
        return this.$g(r, E[0], E[1]);
      };
    }), O.extend = function(E, r) {
      return E.$i || (E(r, W, O), E.$i = !0), O;
    }, O.locale = q, O.isDayjs = Z, O.unix = function(E) {
      return O(1e3 * E);
    }, O.en = M[U], O.Ls = M, O.p = {}, O;
  });
})(ct);
const dt = (e) => typeof e == "function", B = (e) => (t) => t ? `${e}[${t}]` : `${e}`;
B(G.HOST);
B(G.GUEST);
B(G.VIEWER);
B(
  G.SESSION_PRODUCER
);
B(G.SESSION_OWNER);
B(
  G.SESSION_INFO_VIEWER
);
B(G.ACTIVE_STREAMS);
const j = ({
  client: e,
  key: t,
  fn: u,
  skipEmpty: i
}) => {
  const a = e.getQueryData(t);
  i && a === void 0 || e.setQueryData(t, u(a));
}, V = (e) => j({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: dt(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), St = {
  update: V,
  addToArray: (e) => V({
    ...e,
    dataOrFn: (t) => [...e.data, ...t || []]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...u
  }) => V({
    ...u,
    dataOrFn: (i) => i == null ? void 0 : i.map((a) => a[t] === e[t] ? e : a)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...u
  }) => V({
    ...u,
    dataOrFn: (i) => i == null ? void 0 : i.filter((a) => a[t] !== e)
  }),
  addToPages: (e) => j({
    ...e,
    fn: (t) => {
      var i, a, S;
      const u = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return u.pages[0] = {
        ...u.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(i = u.pages[0]) == null ? void 0 : i.data,
          data: e.updateFn((S = (a = u.pages[0]) == null ? void 0 : a.data) == null ? void 0 : S.data)
        }
      }, u;
    }
  }),
  updateInPages: (e) => j({
    ...e,
    fn: (t) => {
      var i, a;
      if (!t || !((i = t.pages) != null && i.length)) return t;
      const u = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let S = 0; S < u.pages.length; S++) {
        const d = (a = u.pages[S]) == null ? void 0 : a.data, g = d == null ? void 0 : d.data;
        if (!g) continue;
        const $ = e.updateFn(g);
        if (d !== $ && (u.pages[S] = {
          ...u.pages[S],
          data: {
            ...d,
            data: $
          }
        }, e.stopOnChange))
          break;
      }
      return u;
    }
  })
}, H = {
  update: j,
  request: St
}, z = "dashboardSessions", yt = "dashboardPastSessions", Rt = "cmsPage", Tt = "cmsPages", Dt = "currentUser", ht = "featuredSessions", $t = "recording", Nt = "recordingStatus", ft = "user", Ct = "session", lt = "sessionPresence", Yt = "sessionAccessRule", it = "sessionAccessRules", tt = "sessionRequestAccess", vt = "sessionPendingRulesStats", Ht = "sessionEpisodes", At = "sessionAiFeed", Mt = "sessionEndSummary", Kt = "sessionViewers", Pt = "paymentDetails", Gt = "subscriptionPlans", Bt = "subscriptionReceipts", wt = "integrationsProviders", bt = "integrationsConnections", Ft = "integrationsGetAction", Ut = "integrationsDirectURL", Lt = "userMeetingTypes", kt = "streamToken", qt = "meetingMemorySuggestions", Wt = "calendarEvent", xt = "salesforceProvider", Vt = "salesforceGetBindingById", Qt = "salesforceGetAccountById", jt = "salesforceGetOpportunityById", Jt = "salesforceListBindableRecordsAsOptions", Zt = "salesforceGetContacts", zt = "salesforceGetLeadById", Xt = "salesforceGetByOwnerSessionRecurrence", te = "hubspotProvider", ee = "hubspotGetBindingById", se = "hubspotGetCompanyById", ne = "hubspotGetDealById", re = "hubspotListBindableRecordsAsOptions", ie = "hubspotGetContacts", oe = "hubspotGetContactById", ae = "hubspotGetByOwnerSessionRecurrence", X = (e, t = "") => [
  At,
  e,
  t
], ue = (e, t = "") => [
  tt,
  e,
  t,
  Q.IN_REVIEW
], Ee = (e, t, u) => [tt, e, t].concat(
  u ? [u] : []
), _e = (e, t = "") => [
  Ct,
  e,
  t
], ce = (e, t = "") => [
  lt,
  e,
  t
], de = (e) => [ft, e], Ot = {
  add: ({
    client: e,
    data: t,
    sessionId: u,
    recurrenceId: i
  }) => {
    t && H.request.addToArray({
      client: e,
      key: X(u, i),
      data: [t]
    });
  },
  update: ({
    client: e,
    data: t,
    sessionId: u,
    recurrenceId: i
  }) => {
    t && H.request.update({
      client: e,
      key: X(u, i),
      dataOrFn: (a) => a ? (a.messages = a.messages.map(
        (S) => S.id === t.id ? t : S
      ), a) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    recurrenceId: u,
    id: i
  }) => {
    H.request.removeFromArray({
      client: e,
      key: X(t, u),
      id: i,
      keyId: "id"
    });
  }
}, Se = {
  ...Ot
}, pt = {
  add: ({ client: e, data: t, userId: u }) => {
    t && H.request.addToPages({
      client: e,
      key: [z, "pg", u],
      updateFn: (i) => ({
        ...i,
        count: ((i == null ? void 0 : i.count) || 0) + 1,
        sessions: [t, ...(i == null ? void 0 : i.sessions) || []]
      })
    });
  },
  update: ({ client: e, data: t, userId: u }) => {
    t && H.request.updateInPages({
      client: e,
      key: [z, "pg", u],
      stopOnChange: !0,
      updateFn: (i) => {
        if (!(i != null && i.sessions)) return i;
        const a = i.sessions.findIndex(
          (d) => d.sessionID === t.sessionID
        );
        if (a === -1) return i;
        const S = {
          ...i,
          sessions: [...i.sessions]
        };
        return S.sessions[a] = t, S;
      }
    });
  },
  remove: ({
    client: e,
    userId: t,
    id: u
  }) => {
    let i;
    return H.request.updateInPages({
      client: e,
      key: [z, "pg", t],
      stopOnChange: !0,
      updateFn: (a) => {
        if (!(a != null && a.sessions)) return a;
        const S = a.sessions.findIndex((g) => g.sessionID === u);
        if (S === -1) return a;
        const d = { ...a, sessions: [...a.sessions] };
        return d.count = Math.max(0, (d.count || 1) - 1), i = d.sessions.splice(S, 1)[0], d;
      }
    }), i;
  }
}, mt = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: u, sessionRecurrenceID: i } = t[0];
    H.request.update({
      client: e,
      key: [
        it,
        u,
        i,
        Q.GRANTED
      ],
      dataOrFn: (a = {}) => {
        var R, F;
        const S = { ...a }, d = (D, Y) => (Y.type === "domain" ? D[0].push(Y) : D[1].push(Y), D), [g, $] = ((F = (R = S == null ? void 0 : S.data) == null ? void 0 : R.viewerAccessRules) == null ? void 0 : F.reduce(d, [[], []])) || [], [N, w] = t.reduce(d, [[], []]);
        return {
          data: {
            viewerAccessRules: [
              ...N,
              ...g || [],
              ...w,
              ...$ || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: u,
    id: i
  }) => {
    H.request.update({
      client: e,
      key: [
        it,
        t,
        u,
        Q.GRANTED
      ],
      dataOrFn: (a) => {
        var d;
        const S = ((d = a == null ? void 0 : a.data.viewerAccessRules) == null ? void 0 : d.filter((g) => g.id !== i)) || [];
        return {
          ...a,
          data: {
            ...a == null ? void 0 : a.data,
            viewerAccessRules: S
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: u,
    data: i
  }) => {
    const a = [
      tt,
      t,
      u,
      Q.IN_REVIEW
    ], d = { ...e.getQueryData(a) };
    if (!d.pages || !d.pages.length || !d.pages[0].data) {
      e.invalidateQueries(a);
      return;
    }
    d.pages[0].data.accessRules = [
      ...i,
      ...d.pages[0].data.accessRules || []
    ], e.setQueryData(a, d);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: u,
    id: i
  }) => {
  }
}, fe = {
  session: pt,
  viewerAccess: mt
}, gt = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, Ce = (e) => gt(e.data), le = (e, t = 0) => {
  var u, i, a;
  return (a = (i = (u = e.data) == null ? void 0 : u.pages[t]) == null ? void 0 : i.data) == null ? void 0 : a.data;
}, et = (e) => (t) => ({
  cacheTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), C = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "3s": 3e3
}, Ae = [""], Oe = () => {
}, I = et(!0), pe = {
  "7d": I(C["7d"]),
  "3d": I(C["3d"]),
  "1d": I(C["1d"]),
  "6h": I(C["6h"]),
  "1h": I(C["1h"]),
  "30m": I(C["30m"]),
  "15m": I(C["15m"]),
  "10m": I(C["10m"]),
  "5m": I(C["5m"]),
  "3m": I(C["3m"]),
  "1m": I(C["1m"]),
  "30s": I(C["30s"]),
  "15s": I(C["15s"]),
  "3s": I(C["3s"]),
  0: I(0)
}, y = et(!1), me = {
  "7d": y(C["7d"]),
  "3d": y(C["3d"]),
  "1d": y(C["1d"]),
  "6h": y(C["6h"]),
  "1h": y(C["1h"]),
  "30m": y(C["30m"]),
  "15m": y(C["15m"]),
  "10m": y(C["10m"]),
  "5m": y(C["5m"]),
  "3m": y(C["3m"]),
  "1m": y(C["1m"]),
  "30s": y(C["30s"]),
  "15s": y(C["15s"]),
  "3s": y(C["3s"]),
  0: y(0)
}, ge = (e) => ({
  ...et(!1)(e),
  refetchOnReconnect: !0
});
export {
  Wt as CACHE_KEY_CALENDAR_EVENT,
  Rt as CACHE_KEY_CMS_PAGE,
  Tt as CACHE_KEY_CMS_PAGES,
  Dt as CACHE_KEY_CURRENT_USER,
  yt as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  z as CACHE_KEY_DASHBOARD_SESSIONS,
  ht as CACHE_KEY_FEATURED_SESSIONS,
  ee as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  ae as CACHE_KEY_HUBSPOT_GET_BY_OWNER_SESSION_RECURRENCE,
  se as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  ie as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  oe as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  ne as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  re as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  te as CACHE_KEY_HUBSPOT_PROVIDER,
  bt as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Ut as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  Ft as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  wt as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  qt as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  Pt as CACHE_KEY_PAYMENT_DETAILS,
  $t as CACHE_KEY_RECORDING,
  Nt as CACHE_KEY_RECORDING_STATUS,
  Qt as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  Vt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Xt as CACHE_KEY_SALESFORCE_GET_BY_OWNER_SESSION_RECURRENCE,
  Zt as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  zt as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  jt as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  Jt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  xt as CACHE_KEY_SALESFORCE_PROVIDER,
  Ct as CACHE_KEY_SESSION,
  Yt as CACHE_KEY_SESSION_ACCESS_RULE,
  it as CACHE_KEY_SESSION_ACCESS_RULES,
  At as CACHE_KEY_SESSION_AI_FEED,
  Mt as CACHE_KEY_SESSION_END_SUMMARY,
  Ht as CACHE_KEY_SESSION_EPISODES,
  vt as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  lt as CACHE_KEY_SESSION_PRESENCE,
  tt as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Kt as CACHE_KEY_SESSION_VIEWERS,
  kt as CACHE_KEY_STREAM_TOKEN,
  Gt as CACHE_KEY_SUBSCRIPTION_PLANS,
  Bt as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  ft as CACHE_KEY_USER,
  Lt as CACHE_KEY_USER_MEETING_TYPES,
  pe as activeOptions,
  Se as aiFeedCacheService,
  ge as baseBraidOptions,
  H as cacheService,
  Ae as emptyCacheKey,
  Oe as emptyFn,
  I as getActiveOptions,
  X as getAiFeedQueryKey,
  y as getInactiveOptions,
  le as getInfinityRequestData,
  Ce as getQueryRequestData,
  gt as getRequestData,
  ce as getSessionPresenceKey,
  _e as getSessionQueryKey,
  de as getUserQueryKey,
  me as inactiveOptions,
  C as milliseconds,
  fe as sessionCacheService,
  ue as sessionPendingAccessQueryKey,
  Ee as userSessionRequestAccessQueryKey
};
